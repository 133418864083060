<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <apexchart :options="this.options" :series="this.series" type="line"
          v-if="this.series != null && this.options != null"></apexchart>
      </v-col>
      <v-col cols="12">

        <v-data-table :headers="this.headers" :items="this.UmsatzTable" :sort-by="['date']" :sort-desc="[false, true]"
          class="elevation-0" multi-sort v-if="this.UmsatzTable != null">
          <template v-slot:item.umsatz="{ item }">
            {{ item.umsatz | currency }}
          </template>

          <template v-slot:item.solds="{ item }">
            {{ item.solds | currency }}
          </template>
        </v-data-table>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ENDPOINTS } from "../../../config";
import VueApexCharts from 'vue-apexcharts'
var self = null;
export default {
  name: "UmsatzWareGroupComponent",
  components: {
    'apexchart': VueApexCharts
  },
  props: {
    month: {
      type: String,
      default: '',
    },
    cashierId: {
      type: [String, Number],
      default: null,
    }
  },
  data: () => {
    return {
      ENDPOINTS,
      options: null,
      series: null,
      UmsatzTable: null,
    }
  },
  mounted() {
    this.loadData();
    self = this;
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_day'),
          align: 'start',
          sortable: false,
          value: 'date',
        },
        { text: this.$t('accounting.lang_turnover'), value: 'umsatz' },
        { text: this.$t('generic.lang_sales'), value: 'fat' },
        { text: this.$t('accounting.lang_turnover') + '/' + this.$t('generic.lang_Verkauf'), value: 'solds' },
        {
          text: this.$t('accounting.lang_returns'), value: 'retouren'
        },
      ]
    }
  }
  ,
  methods: {
    loadData() {

      const chart = this.axios.post(ENDPOINTS.ACCOUNTING.MONTHLYREPORT.GET, {
        month: this.month,
          cashierId: this.cashierId
      }),
        table = this.axios.post(ENDPOINTS.ACCOUNTING.MONTHLYREPORT.UMSATZTABLE, {
          month: this.month,
          cashierId: this.cashierId
        })

      Promise.all([chart, table]).then(this.axios.spread((...res) => {
        this.UmsatzTable = res[1].data.data;

        if (res[0].data && res[0].data.success) {

          this.series = res[0].data.data;
          this.options = {
            chart: {
              type: 'line',
              height: 200
            },
            theme: {
              mode: this.$vuetify.theme.dark ? 'dark' : 'light',
            },
            yaxis: [
              {
                title: {
                  text: this.$t('generic.lang_total')
                },
                labels: {
                  formatter: function (val) {
                    return self.$options.filters.currency(val);
                  }
                }
              },
            ],
            xaxis:
            {
              title: {
                text: this.$t('generic.lang_day')
              },
              categories: res[0].data.labels,
              labels: {
                formatter: function (val) {
                  return val
                }
              }
            }
          };

        }


      }));
    }
    ,
  }

}
</script>

<style scoped></style>